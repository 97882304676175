import React from 'react'
// import { Link } from 'gatsby'

import BlogSearch from './BlogSearch'
import './PostCategoriesNav.css'

const ProductCategoriesNav = ({ categories, enableSearch }) => (
  <div className="PostCategoriesNav">
    {enableSearch && <BlogSearch />}
  </div>
)

export default ProductCategoriesNav
